@import '../node_modules/bootstrap/dist/css/bootstrap.css';

html,
body
{
    height: 100vh;
}

.navbar>li>a:focus,
.navbar>li>a:hover,
.nav-pills>.nav-link.active
{
    background-color: #00ADB5;
}

.temp-text
{
    width: 20rem;
}

h3.center-block
{
    font-size: xx-large;
    color: white;
}

.primary-panel-row
{
    height: 75%;
}

.secondary-panel-row
{
    height: 25%;
}

.bottom-panel-right
{
    background-color: #00ADB5;
}

.bottom-panel-left
{
    background-color: #d6d6d6;
}

.custom-navbar-image
{
    padding-right: 10px;
}